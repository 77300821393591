


























































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import { RctApplicantViewModel } from '../../viewmodels/rct-applicant-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: RctApplicantViewModel
}
